<template>
  <UiPopup
    :model-value="modelValue"
    title="Delete activity"
    description="This scheduled activity will be removed from your calendar. Notifications for this activity will be cancelled."
    primary-button-text="Delete activity"
    secondary-button-text="Cancel"
    size="small"
    :loading="loading"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="submit"
  >
  </UiPopup>
</template>

<script setup lang="ts">
import { useUiStore } from '@/store/ui'
import type { Activity } from '@/types'

const uiStore = useUiStore()

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  activity: Activity
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const loading = ref(false)

const submit = async () => {
  try {
    loading.value = true
    const lead = await useDeleteCalendarActivity(Number(props.activity.id))
    uiStore.showSnackBanner()
    emits('input', lead)
    emits('update:modelValue', false)
  } catch (error) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}
</script>

<style scoped></style>
